import React from "react";
import ReactDOM from "react-dom";
import { IAppState } from "vev";
import * as vev from "./core";
import { App, StateProvider } from "./core";
import polyfills from "./core/polyfills";
import "./globals";
import { fetchPage, getBaseDir } from "./utils/route";
import "./viewer.scss";

declare global {
  interface Window {
    // Vev register widgets
    vevr: WidgetRegisterCB[] | { push: (val: WidgetRegisterCB) => void };
    vevLocal: boolean;
  }
}

const animationName = "vevd";
const states: { [projectKey: string]: IAppState } = {};
const vevNodes: HTMLElement[] = [];

type WidgetRegisterCB = (vev: any, React: any, System: any) => void;
const HTTP_REG = /^(https?:)?\/\//;
if (location.search.includes("viewer=local") && !window.vevLocal) runLocal();
else if (!(window as any).vev) {
  (window as any).vev = vev;

  // Import polyfill
  polyfills().then(() => vev.raf(initVev));
} else {
  console.warn("Multiple vev scripts loaded");
}

function initVev() {
  vev.View.updateSize();
  // Vev widget register list
  const widgetRegister = window.vevr as WidgetRegisterCB[];
  if (widgetRegister) widgetRegister.forEach((cb) => cb(vev, vev.s, React));
  // Replace register with fake array
  window.vevr = { push: (cb: WidgetRegisterCB) => cb(vev, vev.s, React) };

  mapNodes(document.querySelectorAll("noscript.vev-defer"), initDeferredStyles);
  mapNodes(
    document.querySelectorAll('script[type="text/vev"]'),
    initProjectContent
  );
  // Listen for animation start to detect vevroot elements (animationstart is triggered even if the element was added before script was loaded)
  document.addEventListener("animationstart", onNodeDetect, false);
  const detectNodes = [".vev"];
  // Adding style animation to vevroot
  const style = document.createElement("style");
  style.innerHTML = `@keyframes ${animationName}{from{ opacity: 0.99}to{ opacity: 1}}${detectNodes.join(
    ","
  )}{animation-duration: 0.001s;animation-name: ${animationName}}`;

  document.head.appendChild(style);
}

function onNodeDetect(e: AnimationEvent) {
  if (e.animationName === animationName) initVevRoot(e.target as HTMLElement);
}

async function initVevRoot(node: HTMLElement) {
  if (vevNodes.indexOf(node) !== -1) return;
  vevNodes.push(node);
  const path = (node.dataset.path || "")
    .replace(HTTP_REG, "")
    .replace(/\/$/, "");
  // If first path part contains "." it's a domain and we try to fetch it from domain
  if (!states[path] && path.split("/")[0].split(".").length > 1) {
    const [domain, ...projectPath] = path.split("/");
    const { state, html, scripts } = await fetchPage("//" + path);

    states[path] = state;
    state.host =
      "//" +
      domain +
      "/" +
      getBaseDir(projectPath.join("/"), state.route.pageKey, state.pages);
    state.embed = !node.dataset.router;
    node.innerHTML = html;

    await fetchDeps(
      scripts.map((src) => (HTTP_REG.test(src) ? src : "//" + domain + src))
    );
  }

  const state = states[path];
  if (state) {
    state.dir = getBaseDir(location.pathname, state.route.pageKey, state.pages);
    render(state, node);
  }
}

function fetchDeps(deps: string[]) {
  return Promise.all(deps.map((url) => vev.s.fetch(url)));
}

function initDeferredStyles(node: Element) {
  if (node.textContent) {
    const replacement = document.createElement("div");
    replacement.innerHTML = node.textContent || "";
    document.body.appendChild(replacement);
  }
  node.remove();
}

function initProjectContent(node: Element) {
  const json = node.textContent;
  if (json) {
    const state = JSON.parse(decodeURIComponent(json)) as IAppState;
    states[state.project + "/" + state.route.pageKey] = state;
    node.remove();
  }
}

function mapNodes(col: NodeListOf<Element>, cb: (node: Element) => void) {
  for (let i = 0; i < col.length; i++) cb(col[i]);
}

function importAll(state: IAppState): Promise<any> {
  const imports: { [type: string]: boolean } = {};
  for (const { type } of state.models) {
    if (type !== undefined) {
      imports[state.pkg[type] || type] = true;
    }
  }
  return Promise.all(
    Object.keys(imports).map((key) => vev.s.import(key, true))
  );
}

export async function render(
  state: IAppState,
  root: HTMLElement = document.getElementById(
    state.project || ""
  ) as HTMLElement
) {
  if (!root) return console.error("Failed to render vev, missing root node");

  await Promise.all([importAll(state), polyfills()]);

  const jsx = (
    <StateProvider state={state}>
      <App />
    </StateProvider>
  );

  if (root.innerHTML.length > 3) ReactDOM.hydrate(jsx, root);
  else ReactDOM.render(jsx, root);
}

function runLocal() {
  console.log("Running localhost viewer");
  window.vevLocal = true;
  const script = document.createElement("script");
  script.src = "http://localhost:8080/vev.js";
  document.body.appendChild(script);
  const style = document.createElement("link");
  style.rel = "stylesheet";
  style.href = "http://localhost:8080/vev.css";
  document.body.appendChild(style);
}
