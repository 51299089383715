import { doc, root } from '../utils/dom';
import { IModule } from './types';

const tagContainer = doc.head || root;
// the closest we can get to call(undefined)
const nullContext = Object.freeze(Object.create(null));
let currentExecute: IModule | null = null;
export function getCurrentExecute() {
  return currentExecute;
}

export function loadTag(url: string): Promise<void> {
  // If url does not contain // then try to use jsdelivr
  if (!/\/\//.test(url)) url = 'https://cdn.jsdelivr.net/npm/' + url;

  return new Promise((resolve, reject) => {
    const script = doc.createElement('script');
    script.charset = 'utf-8';
    script.async = true;
    // script.crossOrigin = 'anonymous';
    script.onerror = () => reject(new Error('Error loading ' + url));
    script.onload = () => resolve();
    script.src = url;
    tagContainer.appendChild(script);
  });
}

export function loadCss(url: string): HTMLLinkElement {
  const link = document.createElement('link');
  link.href = url;
  link.type = 'text/css';
  link.rel = 'stylesheet';
  tagContainer.appendChild(link);

  return link;
}

export async function topLevelLoad(load: IModule) {
  load.C = instantiateAll(load, {}).then(() => postOrderExec(load, {}));
  await load.C;
  return load.n;
}

async function instantiateAll(load: IModule, loaded: { [loadId: string]: boolean }) {
  if (!loaded[load.id]) {
    loaded[load.id] = true;

    // Need to wait for load.L(loads dependencies to be loaded)
    // may be undefined for already-instantiated
    if (load.L) await load.L;
    if (load.d) await Promise.all(load.d.map((dep) => instantiateAll(dep, loaded)));
  }
}

// returns a promise if and only if a top-level await subgraph
// throws on sync errors
async function postOrderExec(load: IModule, seen: { [loadId: string]: boolean }): Promise<any> {
  if (seen[load.id]) return;
  seen[load.id] = true;

  if (!load.e) {
    if (load.eE) throw load.eE;
    if (load.E) return load.E;
    return;
  }

  // deps execute first, unless circular
  let depLoadPromises: Array<Promise<any>> | undefined = undefined;
  if (load.d) {
    load.d.forEach((depLoad) => {
      const depLoadPromise = postOrderExec(depLoad, seen);
      if (depLoadPromise) (depLoadPromises = depLoadPromises || []).push(depLoadPromise);
    });
  }

  if (depLoadPromises) {
    load.E = Promise.all(depLoadPromises).then(doExec);
  } else {
    return doExec();
  }

  function doExec() {
    try {
      currentExecute = load;
      const execPromise: Promise<void> | undefined | null = load.e && load.e.call(nullContext);
      if (execPromise) {
        execPromise.then(() => {
          // load.C = load.n;
          load.E = null;
        });
        execPromise.catch(() => {});
        return (load.E = load.E || execPromise);
      }
      // (should be a promise, but a minify optimization to leave out Promise.resolve)
      // load.C = load.n;
    } catch (err) {
      load.eE = err;
      throw err;
    } finally {
      load.L = load.I = undefined;
      load.e = null;
    }
  }
}

const backslashRegEx = /\\/g;
export function resolveIfNotPlainOrUrl(relUrl: string, parentUrl: string): string {
  if (relUrl.indexOf('\\') !== -1) relUrl = relUrl.replace(backslashRegEx, '/');
  // protocol-relative
  if (relUrl[0] === '/' && relUrl[1] === '/') {
    return parentUrl.slice(0, parentUrl.indexOf(':') + 1) + relUrl;
  } else if (
    // relative-url
    (relUrl[0] === '.' &&
      (relUrl[1] === '/' ||
        (relUrl[1] === '.' && (relUrl[2] === '/' || (relUrl.length === 2 && (relUrl += '/')))) ||
        (relUrl.length === 1 && (relUrl += '/')))) ||
    relUrl[0] === '/'
  ) {
    const parentProtocol = parentUrl.slice(0, parentUrl.indexOf(':') + 1);
    // Disabled, but these cases will give inconsistent results for deep backtracking
    // if (parentUrl[parentProtocol.length] !== '/')
    //  throw new Error('Cannot resolve');
    // read pathname from parent URL
    // pathname taken to be part after leading "/"
    let pathname;
    if (parentUrl[parentProtocol.length + 1] === '/') {
      // resolving to a :// so we need to read out the auth and host
      pathname = parentUrl.slice(parentProtocol.length + 2);
      pathname = pathname.slice(pathname.indexOf('/') + 1);
    } else {
      // resolving to :/ so pathname is the /... part
      pathname = parentUrl.slice(
        parentProtocol.length + (parentUrl[parentProtocol.length] === '/' ? 0 : 1),
      );
    }

    if (relUrl[0] === '/') {
      return parentUrl.slice(0, parentUrl.length - pathname.length - 1) + relUrl;
    }

    // join together and split for removal of .. and . segments
    // looping the string instead of anything fancy for perf reasons
    // '../../../../../z' resolved to 'x/y' is just 'z'
    const segmented = pathname.slice(0, pathname.lastIndexOf('/') + 1) + relUrl;

    const output: string[] = [];
    let segmentIndex = -1;
    for (let i = 0; i < segmented.length; i++) {
      // busy reading a segment - only terminate on '/'
      if (segmentIndex !== -1) {
        if (segmented[i] === '/') {
          output.push(segmented.slice(segmentIndex, i + 1));
          segmentIndex = -1;
        }
      } else if (segmented[i] === '.') {
        // new segment - check if it is relative
        // ../ segment
        if (segmented[i + 1] === '.' && (segmented[i + 2] === '/' || i + 2 === segmented.length)) {
          output.pop();
          i += 2;
        } else if (segmented[i + 1] === '/' || i + 1 === segmented.length) {
          // ./ segment
          i += 1;
        } else {
          // the start of a new segment as below
          segmentIndex = i;
        }
      } else {
        // it is the start of a new segment
        segmentIndex = i;
      }
    }
    // finish reading out the last segment
    if (segmentIndex !== -1) output.push(segmented.slice(segmentIndex));
    return parentUrl.slice(0, parentUrl.length - pathname.length) + output.join('');
  }

  return relUrl;
}
