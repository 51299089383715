import { isUndefined } from '../core';

declare const dataLayer: any;
declare const ga: any;

type TrackingLayer = (
  event: string,
  category?: string,
  action?: string,
  label?: string,
  value?: any,
) => void;

isUndefined;

class TrackingManager {
  layers: TrackingLayer[] = [];

  add(layer: TrackingLayer) {
    this.layers.push(layer);
  }

  send(
    event: string,
    category?: string,
    action?: string,
    label?: string,
    value?: any,
    nonInteractive?: boolean,
  ): void {
    for (const layer of this.layers) layer.apply(layer, arguments as any);
  }
}

const manager = new TrackingManager();
manager.add(
  (event: string, category?: string, action?: string, label?: string, value?: any): void => {
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({
        event,
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventValue: value,
      });
    }
  },
);

manager.add(
  (
    event: string,
    eventCategory?: string,
    eventAction?: string,
    eventLabel?: string,
    eventValue?: any,
    nonInteractive?: boolean,
  ): void => {
    if (typeof ga !== 'undefined') {
      const gaVars: any = {
        eventCategory,
        eventAction,
        eventLabel,
        eventValue,
        transport: 'beacon',
      };
      if (nonInteractive) gaVars.nonInteractive = nonInteractive;
      ga('send', 'event', gaVars);
    }
  },
);

export default manager;
